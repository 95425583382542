import React, {Component} from 'react';
// import CountUp from 'react-countup';
// import VisibilitySensor from 'react-visibility-sensor';

class CounterupOne extends Component {
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }

    render() {
        let Data = [
            {
                countNum : 95,
                countTitle: 'Web Development',
            },
            {
                countNum: 90,
                countTitle: 'C#'
            },
            {
                countNum : 85,
                countTitle: 'ASP.NET Core',
            },
            {
                countNum : 70,
                countTitle: 'ASP.NET MVC',
            },
            {
                countNum : 75,
                countTitle: 'Python',
            },
            {
                countNum : 60,
                countTitle: 'JavaScript',
            },
            {
                countNum : 59,
                countTitle: 'HTML5/CSS3/Bootsrap',
            },
            {
                countNum : 89,
                countTitle: 'WordPress',
            },
            {
                countNum: 100,
                countTitle: 'Umbraco'
            },
            {
                countNum: 90,
                countTitle: 'Azure'
            },
            {
                countNum: 90,
                countTitle: 'NopCommerce'
            },
            {
                countNum: 90,
                countTitle: 'ReactJS'
            },
            {
                countNum: 90,
                countTitle: 'Data Analysis'
            },
            {
                countNum: 90,
                countTitle: 'Pandas'
            },
            {
                countNum: 90,
                countTitle: 'Matplotlib'
            },
            {
                countNum: 90,
                countTitle: 'Jupyter'
            },
            {
                countNum: 90,
                countTitle: 'Data Visualization'
            },
            {
                countNum: 90,
                countTitle: 'Azure DevOps'
            },
            {
                countNum: 90,
                countTitle: 'Entity Framework Core'
            },
            {
                countNum: 90,
                countTitle: 'Dapper'
            },
            {
                countNum: 90,
                countTitle: 'MSSQL'
            },
            {
                countNum: 90,
                countTitle: 'MySQL'
            },
            {
                countNum: 90,
                countTitle: 'Windows Server'
            },
            {
                countNum: 90,
                countTitle: 'Linux Servers'
            },
            {
                countNum: 90,
                countTitle: 'Containerization'
            }
        ];
        return (
            <ul className="counter-box">
                {Data.map((value, index) => (
                    <li key={index}>
                        <div className="inner">
                            <span className="description">{value.countTitle}</span>
                            {/* <label className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>%
                            </label> */}
                        </div>
                    </li>
                ))}
            </ul>

        )
    }
    
}

export default CounterupOne;