import React from 'react';
// import {Link} from 'gatsby'
import Image from "../image";
import Img from 'gatsby-image'


const Projectcard = ({image, id, title, category, column, url }) => {
    let projectImg;
    if(image.fixed && typeof image.fixed !== 'function'){
        projectImg = <Img fixed={image.fixed} alt={title}/>;
    } else if(image.fluid) {
        projectImg = <Image fluid={image.fluid} alt={title}/>
    } else {
        projectImg = <img src={image.src} alt={title}/>
    }
    
    return (
        <div className={column}>
            <div className="portfolio">
                <div className="thumbnail">
                    {/* <Link to={`/project${id}`}>
                        {projectImg}
                    </Link> */}
                    <a href={url} target="_blank" rel="noreferrer">{projectImg}</a>
                </div>
                <div className="content">
                    <div className="inner">
                        <div className="title_abs">
                            <div className="layer"></div>
                        </div>
                        {/* {category && <span className="category"><Link to={`/project/${id}`}>{category}</Link></span>} */}
                        {category && <span className="category">{category}</span>}
                        {/* {title && <h4 className="title"><Link to={`/project/${id}`}>{title}</Link></h4>} */}
                        {/* {title && <h4 className="title text-white">{title}</h4>} */}
                        {title && <h4 className="title text-white"><a href={url} target="_blank" rel="noreferrer">{title}</a></h4>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Projectcard;