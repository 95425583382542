import React from 'react';

const Skill = ( ) => {
    return (
        <div className="rn-about-area rn-section-gap line-separator" id="timeline">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h3 className="title">My timeline</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="experience_inner">
                            <div className="single-list">
                                <div className="list_inner">
                                    <p><span>2019 - Today</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Software Engineer</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Hubnyx Ltd, Nairobi</span></p>
                                </div>
                            </div>
                            
                            <div className="single-list">
                                <div className="list_inner">
                                    <p><span>August, 2018 - December, 2018</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Web Developer Intern</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Legibra, Nairobi</span></p>
                                </div>
                            </div>

                            <div className="single-list">
                                <div className="list_inner">
                                    <p><span>May 2017 - July 2019</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Part-time Systems Integration Developer</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Muriash, Nairobi</span></p>
                                </div>
                            </div>

                            <div className="single-list">
                                <div className="list_inner">
                                    <p><span>September, 2014 - April, 2018</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>BSc in Maths & Computer Science</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Kirinyaga University, Central Kenya</span></p>
                                </div>
                            </div>

                            <div className="single-list">
                                <div className="list_inner">
                                    <p><span>August 1995</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>It all Begins Here!</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Nairobi, Kenya</span></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skill;
